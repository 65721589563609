<template>
    <div>
        <section class="header-image">
            <div class="header-image-overlay"></div>
            <b-container>
                <b-row>
                    <b-col lg="7">
                        <h1>Proyectos I+D+i</h1>
                        <p class="lead">
                            Desarrollamos proyectos basados en SATCOMs y tecnologías 4.0.
                        </p>
                    </b-col>
                    <b-col md="5">
                        <div class="header-breadcrumb">
                            <router-link class="link-alt" :to="{ name: $i18n.locale + '_home' }">{{
                                $t('message.home.title') }}
                            </router-link>
                            • <span class="text-muted">Proyectos I+D+i</span>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </section>
        <section class="section">
            <b-container>
                <b-card no-body class="mb-3">
                  <img class="img-fluid" alt="BONO DE INNOVACIÓN DE SYNTELIX AVANCES TECNOLÓGICOS, SL." :src="require('@/assets/images/bono-innovacion.webp')">
                </b-card>
                <b-card no-body class="mb-3">
                    <b-card-header class="d-flex align-items-center">
                        <img class="img-fluid" alt="MIA Marine IOT Agent" style="height: 40px" :src="require('@/assets/images/mia.svg')">
                    </b-card-header>
                    <b-row no-gutters>
                        <b-col md="4" class="text-center border-right d-flex flex-column justify-content-between">
                            <div class="p-4">
                                <p class="lead mb-1">MIA - Marine IOT Agent</p>
                                <p>08/2018 - 07/2020</p>
                                <img class="img-fluid my-3" alt="CDTI" :src="require('@/assets/images/cdti.png')" />
                                <img class="img-fluid" alt="UE" :src="require('@/assets/images/ue_feder.png')" />
                            </div>
                            <div class="text-center p-4">
                                <img class="img-fluid mx-3 my-2" alt="CTN" :src="require('@/assets/images/ctn.jpg')" style="height: 50px">
                            </div>
                        </b-col>
                        <div class="col-md-8">
                            <div class="p-4">
                                <table>
                                    <tbody>
                                    <tr>
                                        <td style="width: 150px" valign="top" class="font-weight-bold py-1">Convocatoria</td>
                                        <td valign="top" class="py-1">Proyecto de Investigación y Desarrollo</td>
                                    </tr>
                                    <tr>
                                        <td valign="top"  class="font-weight-bold py-1">Nº de expediente</td>
                                        <td valign="top"  class="py-1">IDI-20190086</td>
                                    </tr>
                                    <tr>
                                        <td valign="top"  class="font-weight-bold py-1">Título</td>
                                        <td valign="top"  class="py-1">Marine IOT Agent</td>
                                    </tr>
                                    <tr>
                                        <td valign="top"  class="font-weight-bold py-1">Acrónimo</td>
                                        <td valign="top"  class="py-1">MIA</td>
                                    </tr>
                                    </tbody>
                                </table>

                                <hr>

                                <h4>Objetivo</h4>

                                <p>El objetivo de este proyecto es el diseño, desarrollo y validación experimental de
                                    una Interfaz marina de IoT (MIA) en el contexto de la Industria naval, que
                                    proporcione accesibilidad global mediante IoT a todos los sistemas electrónicos de
                                    un buque.</p>


                                <h4>Descripción</h4>

                                <p>MIA es un sistema capaz de proporcionar accesibilidad global mediante IoT a todos los
                                    sistemas electrónicos de un buque por medio de un agente de a bordo y una
                                    infraestructura de Cloud Computing, basada en microservicios, endpoints y API
                                    REST.</p>

                                <p>El sistema distingue tres partes diferenciables: el agente de a bordo MIA, los
                                    servicios Cloud Computing y las interfaces de usuario</p>

                                <p>La duración del proyecto se extiende desde Agosto de 2018 a Julio de 2020.</p>

                                <h4>Consorcio</h4>

                                <p>El proyecto está liderado por SYNTELIX, que tiene su sede operativa en la ciudad de
                                    Vigo, y cuenta en su desarrollo con el Centro Tecnológico Naval y del Mar (CTNAVAL)
                                    y con financiación de CDTI a través de los fondos FEDER.</p>

                                <h4>Syntelix</h4>

                                <p>Syntelix provee soluciones de comunicación por satélite a empresas y organizaciones,
                                    no importa cual sea su ubicación, gracias a un enfoque integrado de tecnologías vía
                                    satélite de última generación en entornos de movilidad, remotos o críticos.</p>

                                <p>Cuenta con un departamento de desarrollo propio con objeto de diseñar soluciones y
                                    servicios innovadores en materia de comunicación vía satélite, con un claro enfoque
                                    a cliente.</p>

                                <h4>CTNAVAL. Centro Tecnológico Naval y del Mar</h4>

                                <p>El propósito principal del CTNAVAL es mejorar la competitividad de las empresas
                                    mediante el desarrollo de actuaciones innovadoras. Para ello, el CTNAVAL cuenta con
                                    un equipo multidisciplinar de profesionales que cubren desde la ingeniería naval e
                                    industrial hasta la biología y oceanografía</p>

                                <p>El CTNAVAL cuenta con unas instalaciones propias de mas de 2.200 m2, dotadas con
                                    laboratorios y talleres para el desarrollo de proyectos de I+D+i, así como con salas
                                    de usos múltiples para la realización de cursos y todo tipo de eventos y jornadas
                                    tecnológicas</p>

                                <p>Desde sus inicios, el CTNAVAL ha desarrollado diversos proyectos de I+D+i para
                                    astilleros, empresas de la industria auxiliar naval y la acuicultura. El CTNAVAL es
                                    además entidad promotora de la AEI Clúster Naval y del Mar de la Región de Murcia,
                                    participando activamente en el desarrollo de su Plan Estratégico.</p>
                            </div>
                        </div>
                    </b-row>
                </b-card>
            </b-container>
        </section>
    </div>
</template>

<script>
    export default {
        name: "ResearchAndDevelopment",
        metaInfo() {
            return {
                htmlAttrs: {
                    lang: this.$i18n.locale
                },
                title: this.$t('meta.research_and_development.title'),
                link: [
                    { rel: 'canonical', href: 'https://www.syntelix.net' + this.$route.path.replace(/\/$/, "") }
                ],
                meta: [
                    {
                        vmid: 'description',
                        name: 'description',
                        content: this.$t('meta.research_and_development.description')
                    },
                    {
                        vmid: 'og:title',
                        property: 'og:title',
                        content: this.$t('meta.research_and_development.title')
                    },
                    {
                        vmid: 'og:description',
                        property: 'og:description',
                        content: this.$t('meta.research_and_development.description')
                    },
                    {
                        vmid: 'og:type',
                        property: 'og:type',
                        content: 'website'
                    },
                    {
                        vmid: 'og:image',
                        property: 'og:image',
                        content: 'https://syntelix-website.s3-eu-west-1.amazonaws.com/og.jpg'
                    },
                    {
                        vmid: 'og:url',
                        property: 'og:url',
                        content: 'https://www.syntelix.net' + this.$route.path
                    }
                ]
            }
        }
    }
</script>

<style lang="scss" scoped>
    .anchor-target::before {
        content: "";
        display: block;
        height: 80px; /* fixed header height*/
        margin: -80px 0 0; /* negative fixed header height */
    }
</style>
